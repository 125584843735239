<template >
    <nav role="navigation"  class="nav" :class="{'opened': burgerCheck}" ref="refNav">
        <div class="container nav__body">
            <!-- <input type="checkbox" class="nav__toggle-checkbox" id="burgerCheck"/> -->
            
            <a href="#" class="nav__logo">
                <router-link to="/#top">
                    <img :src="require('@/assets/logo-solodoor.png')" alt="Logo Solodoor s.r.o.">
                </router-link>
            </a>

            <ul class="nav__items ml-a">
                <router-link :to="({path: '/produkty', query: {type: 'products', product: 'none'}})" sec="produkty">
                    <li>Produkty</li>
                </router-link>

                <router-link to="/#o-spolecnosti" sec="o-spolecnosti">
                    <li>O společnosti</li>
                </router-link>
                <router-link to="/#informace" sec="informace">
                    <li>Informace</li>
                </router-link>
                <router-link to="/#katalog-a-cenik" sec="katalog-a-cenik">
                    <li>Katalog a ceník</li>
                </router-link>
                <router-link to="/#prodejci" sec="prodejci">
                    <li>Prodejna</li>
                </router-link>
                <a href="https://www.sestavsidvere.cz/cs/" target="_blank" title="Dveře Solodoor Pardubice Chrudim" class="btn">
                    Konfigurátor
                </a>
            </ul>

            <div class="nav__burger" @click="openBurger">
                <span></span>
                <span></span>
                <span></span>
            </div>

            <div></div>
        </div>
    </nav>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch } from 'vue'

import { useRoute, useRouter } from 'vue-router'

import "@/assets/scripts/nav.js"

export default defineComponent({
    name: 'Nav',
    setup() {
        const route = useRoute()

        var burgerCheck = ref(false)
        const refNav = ref<null | { clientHeight: () => null}>(null)

        function openBurger() {
            burgerCheck.value = !burgerCheck.value
        }

        watch(route, () => {
                burgerCheck.value = false
            }
        )

        return {
            refNav,
            route,
            burgerCheck,
            openBurger
        }
  },
   watch: {
      $route (to, from) {
          if(from.name == "Produkty" && to.name != "Produkty") {
              setTimeout(() => {
                    if(this.$router.currentRoute.value!.hash) {
                        var el = document.querySelector(this.$router.currentRoute.value!.hash)
                        var offsetPos = el!.getBoundingClientRect().top - 100
                        window.scrollTo({top: offsetPos})
                    }
              }, 500);
          } else if (from.name == "Produkty" && to.name == "Produkty" && window.outerWidth <= 640) {
                var el = document.getElementById("aside")
                var offsetPos = el!.offsetHeight + 70
                console.log(offsetPos)
                window.scrollTo({top: offsetPos})
          } else if (from.name != "Produkty" && to.name == "Produkty") {
                window.scrollTo({top: 0})
          }
      } 
  }
});
</script>

<style lang="scss">
    
</style>