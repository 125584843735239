<template>
  <footer class="footer">
    <div class="container d-f jc-sb ">
      <div class="footer__items">
        <p>&copy; 2012-2021, Domal s.r.o. - všechna práva vyhrazena</p>
        <div class="d-f ai-c jc-c ac-c">
          <div class="d-f ai-c mt-sm mr-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 512 512"
              style="enable-background:new 0 0 512 512;"
              xml:space="preserve"
            >
              <circle style="fill:#5F98D1;" cx="256" cy="256" r="256" />
              <path
                style="fill:#3A6DA1;"
                d="M415.813,147.466H95.558l116.019,120.806l33.48,33.9c-18.216-4.164-19.343-6.759-27.415-13.349  c-4.234-3.457-12.414-12.852-24.838-25.54C154.023,223.682,88.217,156.484,88.217,156.484v198.822l8.265,8.265l-0.925,0.963  L242.68,511.657c4.412,0.226,8.852,0.343,13.32,0.343c141.385,0,256-114.615,256-256c0-4.246-0.11-8.466-0.313-12.661  L415.813,147.466z"
              />
              <polygon
                style="fill:#FFFFFF;"
                points="88.217,156.484 88.217,355.306 185.175,255.493  "
              />
              <polygon
                style="fill:#FFFFFF;"
                points="423.783,156.484 423.783,355.306 327.307,255.895  "
              />
              <path
                style="fill:#FFFFFF;"
                d="M95.558,147.466h320.255L289.948,278.524c-8.958,9.327-21.331,14.599-34.262,14.599   s-25.304-5.272-34.262-14.599L95.558,147.466z"
              />
              <path
                style="fill:#FFFFFF;"
                d="M297.209,285.496c-10.799,11.244-25.933,17.694-41.523,17.694c-15.589,0-30.724-6.448-41.522-17.693   l-21.349-22.23L95.558,364.534h320.255l-97.256-101.267L297.209,285.496z"
              />
            </svg>
            <a href="mailto:info@domal.cz">info@domal.cz</a>
          </div>
          <div class="d-f ai-c  mt-sm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 512 512"
              data-name="Layer 1"
            >
              <linearGradient
                id="linear-gradient"
                gradientUnits="userSpaceOnUse"
                x1="186.516"
                x2="566.38"
                y1="161.751"
                y2="541.615"
              >
                <stop offset="0" stop-color="#1d1d1b" />
                <stop offset=".369" stop-color="#1d1d1b" stop-opacity=".856" />
                <stop offset=".75" stop-color="#1d1d1b" stop-opacity=".429" />
                <stop offset="1" stop-color="#1d1d1b" stop-opacity="0" />
              </linearGradient>
              <circle cx="256" cy="256" fill="#1877f2" r="250" />
              <path
                d="m483.689 359.371a22.7 22.7 0 0 1 3.035-6.966 249.219 249.219 0 0 0 19.276-96.405c0-1.789-.03-3.57-.068-5.35l-161.921-161.921a426.8 426.8 0 0 0 -46.539-2.379c-46.054 0-77.573 28.1-77.573 79.729v44.521h-51.91v60.5l51.911 51.9v102.65l77 77a250.317 250.317 0 0 0 186.789-143.279z"
                fill="url(#linear-gradient)"
                opacity=".49"
              />
              <path
                d="m282.345 425.65v-154.55h52.1l7.806-60.5h-59.906v-38.529c0-17.475 4.851-29.382 29.908-29.382h31.757v-53.961a427.147 427.147 0 0 0 -46.539-2.378c-46.054 0-77.573 28.1-77.573 79.729v44.521h-51.909v60.5h51.911v154.55z"
                fill="#fff"
              />
            </svg>
            <a
              href="https://www.facebook.com/Domal-1733086793641737"
              target="_blank"
              >Domal</a
            >
          </div>
        </div>
      </div>
      <div class="d-f fd-c ta-r mobile-gap">
        <p>
          Vytvořil
          <a
            href="http://www.marosjanota.cz"
            title="Maroš Janota"
            target="_blank"
            >&copy; Maroš Janota</a
          >
        </p>
        <p>
          <a href="#" data-cc="c-settings" aria-haspopup="dialog"
            >Nastevení Cookies</a
          >
        </p>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Footer",
});
</script>

<style lang="scss" scoped>
@import "@/resources/styles/Base/Mixins/_responsive.scss";

.footer {
  background-color: var(--color-primary);
  color: var(--color-primary-text);
  padding: var(--gap-lg);

  @include res-max-tablet {
    > .container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .mobile-gap {
      margin-top: var(--gap-lg);
    }
  }

  a {
    text-decoration: none;
    color: var(--color-primary-text);
  }

  p {
    margin: var(--gap-sm) 0;
  }
}

svg {
  height: 30px;
  margin-right: var(--gap-md);
}
</style>
